var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title mt0" }, [
          _vm._v(_vm._s(_vm.pageTitle)),
        ]),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _vm.msgVo.blStCd === ""
              ? [
                  _vm.blErrorMsg.length > 0
                    ? [
                        _c("h4", { staticClass: "hidden" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONIM070P020.009"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "bul_list_sm t2" },
                          _vm._l(_vm.blErrorMsg, function (item, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "pl-3" }, [
                                _vm._v(_vm._s(item.message)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.msgVo.blStCd === "11" ||
            _vm.msgVo.blStCd === "01" ||
            _vm.msgVo.blStCd === "02" ||
            _vm.msgVo.blStCd === "03" ||
            _vm.msgVo.blStCd === "14" ||
            _vm.msgVo.blStCd === "16" ||
            _vm.msgVo.blStCd === "17"
              ? [
                  _vm.msgVo.blStCd === "11" ||
                  _vm.msgVo.blStCd === "01" ||
                  _vm.msgVo.blStCd === "02" ||
                  _vm.msgVo.blStCd === "03"
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            "* " + _vm._s(_vm.pageTitle.split(" ")[0]) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "* On board Date : " + _vm._s(_vm.msgVo.obrdDt)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                      ])
                    : _vm._e(),
                  _c("h4", [
                    _vm._v("[" + _vm._s(_vm.$t("msg.SETT010T010.102")) + "]"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "* " +
                        _vm._s(_vm.$t("msg.SETT010T010.032")) +
                        " : " +
                        _vm._s(_vm.blApvUser.blApvNm) +
                        " " +
                        _vm._s(
                          _vm.blApvUser.blApvTel === "--"
                            ? ""
                            : _vm.blApvUser.blApvTel
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "* " +
                        _vm._s(_vm.$t("msg.SETT010T010.033")) +
                        " : " +
                        _vm._s(_vm.blApvUser.issuedBy)
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.msgVo.blStCd === "11" || _vm.msgVo.blStCd === "01"
                    ? [
                        _vm.msgVo.blPcupDt !== ""
                          ? _c("div", [
                              _vm._v(
                                " * " +
                                  _vm._s(_vm.$t("msg.ADD20220221.007")) +
                                  " : " +
                                  _vm._s(_vm.$t("msg.SETT010T010.035")) +
                                  " (" +
                                  _vm._s(_vm.msgVo.blPcupDt) +
                                  ") "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "* " +
                                  _vm._s(_vm.$t("msg.ADD20220221.007")) +
                                  " : " +
                                  _vm._s(_vm.$t("msg.ADD20220221.005")) +
                                  " "
                              ),
                            ]),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _c("br"),
            (_vm.msgVo.blStCd === "11" ||
              _vm.msgVo.blStCd === "01" ||
              _vm.msgVo.blStCd === "16") &&
            _vm.memberDetail.userCtrCd !== "KR" &&
            _vm.msgVo.oblContent !== ""
              ? [
                  _c("h4", [_vm._v("[Reject/Pending Content]")]),
                  _c("div", [
                    _vm._v(
                      "* " +
                        _vm._s(_vm.$t("msg.SETT010T010.033")) +
                        " : " +
                        _vm._s(_vm.msgVo.oblContent)
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.msgVo.blStCd === "04" ||
            _vm.msgVo.blStCd === "06" ||
            _vm.msgVo.blStCd === "07" ||
            _vm.msgVo.blStCd === "15"
              ? [
                  _vm.blErrorMsg.length > 0
                    ? [
                        _c("h4", { staticClass: "hidden" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONIM070P020.009"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "bul_list_sm t2" },
                          _vm._l(_vm.blErrorMsg, function (item, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "pl-3" }, [
                                _vm._v(_vm._s(item.message)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    : [
                        _vm.msgVo.blStCd === "06" ? void 0 : _vm._e(),
                        _c("h4", [
                          _vm._v('There\'s no "Reject/Pending Content"'),
                        ]),
                        _c("br"),
                      ],
                ]
              : _vm._e(),
            _vm.msgVo.blStCd === "88" ||
            _vm.msgVo.blStCd === "89" ||
            _vm.msgVo.blStCd === "12" ||
            _vm.msgVo.blStCd === "13"
              ? [
                  _vm.$_.trim(_vm.msgVo.rejectCont) !== ""
                    ? [
                        _c("h4", [
                          _vm._v("[" + _vm._s(_vm.$t("msg.CMBA380.009")) + "]"),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.msgVo.rejectCont))]),
                        _c("div", { staticClass: "mb-3" }, [
                          _vm._v(_vm._s(_vm.msgVo.loiTelNo)),
                        ]),
                      ]
                    : _vm._e(),
                  _c("h4", [
                    _vm._v("[" + _vm._s(_vm.$t("msg.CMBA380.030")) + "]"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "* " +
                        _vm._s(_vm.$t("msg.SETT010T010.032")) +
                        " : " +
                        _vm._s(_vm.blApvUser.blApvNm) +
                        " " +
                        _vm._s(
                          _vm.blApvUser.blApvTel === "--"
                            ? ""
                            : _vm.blApvUser.blApvTel
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "* " +
                        _vm._s(_vm.$t("msg.SETT010T010.033")) +
                        " : " +
                        _vm._s(_vm.blApvUser.issuedBy)
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.msgVo.blStCd === "99"
              ? [
                  _vm.blErrorMsg.length > 0
                    ? [
                        _c(
                          "ul",
                          { staticClass: "bul_list_sm t2" },
                          _vm._l(_vm.blErrorMsg, function (item, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "pl-3" }, [
                                _vm._v(_vm._s(item.message)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    : [
                        _c("ul", { staticClass: "bul_list_sm t2" }, [
                          _c("li", [
                            _c("div", { staticClass: "pl-3" }, [
                              _vm._v(_vm._s(_vm.$t("art.CSBL220.020"))),
                            ]),
                          ]),
                        ]),
                      ],
                ]
              : _vm._e(),
            false
              ? [
                  _vm.$_.trim(_vm.msgVo.rejectCont) !== ""
                    ? [
                        _c("h4", [_vm._v("Reject")]),
                        _c("div", [_vm._v(_vm._s(_vm.msgVo.rejectCont))]),
                        _c("div", { staticClass: "mb-3" }, [
                          _vm._v(_vm._s(_vm.msgVo.loiTelNo)),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.loiReqErrMsg.length > 0
                    ? [
                        _c("h4", [_vm._v(_vm._s(_vm.$t("msg.CMBA380.009")))]),
                        _c(
                          "ul",
                          { staticClass: "bul_list_sm t2" },
                          _vm._l(_vm.loiReqErrMsg, function (msg, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "pl-3" }, [
                                _vm._v(_vm._s(msg)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  _vm.$_.trim(_vm.reqErrMsg) !== ""
                    ? [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t("msg.ADD20220221.008"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.reqErrMsg) },
                        }),
                      ]
                    : _vm._e(),
                  _vm.blErrorMsg.length > 0
                    ? [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t("msg.ONIM070P020.009"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "bul_list_sm t2" },
                          _vm._l(_vm.blErrorMsg, function (item, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "pl-3" }, [
                                _vm._v(_vm._s(item.message)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }