<template>
  <div class="popup_wrap" style="width:500px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title mt0">{{ pageTitle }}</h1>
      <div class="content_box">
        <template v-if="msgVo.blStCd === ''">
          <!-- 미요청 -->
          <template v-if="blErrorMsg.length > 0">
            <h4 class="hidden">{{ $t('msg.ONIM070P020.009') }}</h4> <!-- 불가사유 -->
            <ul class="bul_list_sm t2">
              <li v-for="(item, index) in blErrorMsg" :key="index">
                <div class="pl-3">{{ item.message }}</div>
              </li>
            </ul>
          </template>
        </template>

        <template v-if="msgVo.blStCd === '11' || msgVo.blStCd === '01' || msgVo.blStCd === '02' || msgVo.blStCd === '03'
          || msgVo.blStCd === '14' || msgVo.blStCd === '16' || msgVo.blStCd === '17'"
        >
          <div v-if="msgVo.blStCd === '11' || msgVo.blStCd === '01' || msgVo.blStCd === '02' || msgVo.blStCd === '03'">
            <div>* {{ pageTitle.split(' ')[0] }} </div> <!-- B/L Type -->
            <div>* On board Date : {{ msgVo.obrdDt }}</div> <!-- On board Date -->
            <br>
          </div>

          <!-- 발급/픽업정보 -->
          <!-- {{ msgVo.blStCd }} -->
          <h4>[{{ $t('msg.SETT010T010.102') }}]</h4> <!-- 발급/픽업정보 -->
          <div>* {{ $t('msg.SETT010T010.032') }} : {{ blApvUser.blApvNm }} {{ blApvUser.blApvTel === '--' ? '' : blApvUser.blApvTel }}</div> <!-- 발급담당자 -->
          <div>* {{ $t('msg.SETT010T010.033') }} : {{ blApvUser.issuedBy }}</div> <!-- 발급처 -->

          <template v-if="msgVo.blStCd === '11' || msgVo.blStCd === '01'">
            <div v-if="msgVo.blPcupDt !== ''">
              * {{ $t('msg.ADD20220221.007') }} : {{ $t('msg.SETT010T010.035') }} ({{ msgVo.blPcupDt }})
            </div>
            <div v-else>* {{ $t('msg.ADD20220221.007') }}  : {{ $t('msg.ADD20220221.005') }} </div> <!-- 픽업여부 : 픽업되지 않음 -->
          </template>
        </template>
        <br>
        <template v-if="(msgVo.blStCd === '11' || msgVo.blStCd === '01' || msgVo.blStCd === '16') && memberDetail.userCtrCd !== 'KR' && msgVo.oblContent !== ''">
          <h4>[Reject/Pending Content]</h4>
          <div>* {{ $t('msg.SETT010T010.033') }} : {{ msgVo.oblContent }}</div> <!-- 발급처 -->
        </template>

        <template v-if="msgVo.blStCd === '04' || msgVo.blStCd === '06' || msgVo.blStCd === '07' || msgVo.blStCd === '15'">
          <!-- B/L 발행중 -->
          <template v-if="blErrorMsg.length > 0">
            <h4 class="hidden">{{ $t('msg.ONIM070P020.009') }}</h4> <!-- 불가사유 -->
            <ul class="bul_list_sm t2">
              <li v-for="(item, index) in blErrorMsg" :key="index">
                <div class="pl-3">{{ item.message }}</div>
              </li>
            </ul>
          </template>

          <template v-else>
            <template v-if="msgVo.blStCd === '06'"></template>
            <h4>There's no "Reject/Pending Content"</h4>
            <br>
          </template>
        </template>

        <template v-if="msgVo.blStCd === '88' || msgVo.blStCd === '89' || msgVo.blStCd === '12' || msgVo.blStCd === '13'">
          <!-- 발행거절, PENDING -->
          <!-- <template v-if="loiReqErrMsg.length > 0">
            <h4>[{{ $t('msg.CMBA380.009') }}]</h4>
            <ul class="bul_list_sm t2">
              <li v-for="(msg, index) in loiReqErrMsg" :key="index">
                <div class="pl-3">{{ msg }}</div>
              </li>
            </ul>
          </template> -->

          <template v-if="$_.trim(msgVo.rejectCont) !== ''">
            <h4>[{{ $t('msg.CMBA380.009') }}]</h4> <!-- 사유 -->
            <div>{{ msgVo.rejectCont }}</div>
            <div class="mb-3">{{ msgVo.loiTelNo }}</div>
          </template>

          <h4>[{{ $t('msg.CMBA380.030') }}]</h4> <!-- 담당자  -->
          <div>* {{ $t('msg.SETT010T010.032') }} : {{ blApvUser.blApvNm }} {{ blApvUser.blApvTel === '--' ? '' : blApvUser.blApvTel }}</div> <!-- 발급담당자 -->
          <div>* {{ $t('msg.SETT010T010.033') }} : {{ blApvUser.issuedBy }}</div> <!-- 발급처 -->
        </template>

        <template v-if="msgVo.blStCd === '99'">
          <!-- 요청불가(사유표기) -->
          <!-- <template v-if="reqErrMsg.length > 0">
            <div>
              <ul class="bul_list_sm t2">
                <li v-for="(item, index) in reqErrMsg" :key="index">
                  <div class="pl-3">{{ item }}</div>
                </li>
              </ul>
            </div>
          </template> -->

          <template v-if="blErrorMsg.length > 0">
            <!-- <h4>{{ $t('msg.ONIM070P020.009') }}</h4> 불가사유 -->
            <ul class="bul_list_sm t2">
              <li v-for="(item, index) in blErrorMsg" :key="index">
                <div class="pl-3">{{ item.message }}</div>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul class="bul_list_sm t2">
              <li>
                <div class="pl-3">{{ $t('art.CSBL220.020') }}</div>
              </li>
            </ul>
          </template>
        </template>

        <template v-if="false">
          <template v-if="$_.trim(msgVo.rejectCont) !== ''">
            <h4>Reject</h4>
            <div>{{ msgVo.rejectCont }}</div>
            <div class="mb-3">{{ msgVo.loiTelNo }}</div>
          </template>
          <template v-if="loiReqErrMsg.length > 0">
            <h4>{{ $t('msg.CMBA380.009') }}</h4>
            <ul class="bul_list_sm t2">
              <li v-for="(msg, index) in loiReqErrMsg" :key="index">
                <div class="pl-3">{{ msg }}</div>
              </li>
            </ul>
          </template>
          <template v-if="$_.trim(reqErrMsg) !== ''">
            <h4>{{ $t('msg.ADD20220221.008') }}</h4> <!-- 일반 -->
            <div v-html="reqErrMsg"></div>
          </template>
          <template v-if="blErrorMsg.length > 0">
            <h4>{{ $t('msg.ONIM070P020.009') }}</h4> <!-- 불가사유 -->
            <ul class="bul_list_sm t2">
              <li v-for="(item, index) in blErrorMsg" :key="index">
                <div class="pl-3">{{ item.message }}</div>
              </li>
            </ul>
          </template>
        </template>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import calcIssueApi from '@/api/rest/settle/calcIssue'
import { rootComputed } from '@/store/helpers'
import moment from 'moment'

export default {
  name: 'CalcIssueBlMsgPop',
  components: {},
  props: {
    detail: {
      type: Object,
        default: function () {
        return {}
      }
    },
    isShowPop: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      pageTitle: this.$t('msg.ADMN030G020.013'), //'제목',
      isShow: false,
      errorCodes: [],
      msgData: {},
      msgVo: {},
      loiReqErrMsg: [],
      msgStatus: false,

      reqErrMsg: [],

      blErrorMsg: [],
      blApvUser: {
        blApvNm: '',
        blApvTel: '',
        issuedBy: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    isShowPop (nv, ov) {
      this.isShow = nv
    },
    detail: {
      handler () {
        this.setData()
      },
      deep: true
    }
  },
  async created () {
    console.log('----- calcBlMsg -----')
    // 기초 Data 셋팅
    await this.getBlErrorCodes()
  },
  async mounted () {
    if (this.errorCodes.length === 0) {
      await this.getBlErrorCodes()
      this.setData()
    }
  },
  methods: {
    setData () {
      console.log('## CalcIssueBlMsgPop > Set Data')
      this.msgData = this.$_.cloneDeep(this.detail)

      this.displayMsg()
    },
    displayMsg () {
      const vo = {
        blNo: this.msgData.blNo,
        obrdDt: this.msgData.obrdDt ? this.msgData.obrdDt : '',
        blStCd: this.msgData.blStCd ? this.msgData.blStCd : '00',
        loiReqCatCd: this.msgData.loiReqCatCd ? this.msgData.loiReqCatCd : '',
        loiEtcRmk: this.msgData.loiEtcRmk ? this.msgData.loiEtcRmk : '',
        loiTelNo: this.msgData.loiTelNo ? this.msgData.loiTelNo : '',
        cntrCfsYn: this.msgData.cntrCfsYn,
        certiYn: this.msgData.certiYn,
        elYn: this.msgData.elYn,
        rejectCont: this.msgData.rejectCont,
        cntrStsCd: this.msgData.cntrStsCd ? this.msgData.cntrStsCd : '',
        oblNm: this.msgData.oblNm ? this.msgData.oblNm : '',
        surNm: this.msgData.surNm ? this.msgData.surNm : '',
        wayNm: this.msgData.wayNm ? this.msgData.wayNm : '',
        rejectNm: this.msgData.rejectNm ? this.msgData.rejectNm : '',
        oblUno: this.msgData.oblUno ? this.msgData.oblUno : '',
        surUno: this.msgData.surUno ? this.msgData.surUno : '',
        wayUno: this.msgData.wayUno ? this.msgData.wayUno : '',
        rejectUno: this.msgData.rejectUno ? this.msgData.rejectUno : '',
        oblTel: this.msgData.oblTel ? this.msgData.oblTel : '',
        surTel: this.msgData.surTel ? this.msgData.surTel : '',
        wayTel: this.msgData.wayTel ? this.msgData.wayTel : '',
        rejectTel: this.msgData.rejectTel ? this.msgData.rejectTel : '',
        oblArea: this.msgData.oblArea ? this.msgData.oblArea : '',
        surArea: this.msgData.surArea ? this.msgData.surArea : '',
        wayArea: this.msgData.wayArea ? this.msgData.wayArea : '',
        rejectArea: this.msgData.rejectArea ? this.msgData.rejectArea : '',
        oblAreaNm: this.msgData.oblAreaNm ? this.msgData.oblAreaNm : '',
        surAreaNm: this.msgData.surAreaNm ? this.msgData.surAreaNm : '',
        wayAreaNm: this.msgData.wayAreaNm ? this.msgData.wayAreaNm : '',
        rejectAreaNm: this.msgData.rejectAreaNm ? this.msgData.rejectAreaNm : '',
        blPcupNo: this.msgData.blPcupNo ? this.msgData.blPcupNo : '',
        blPcupDt: !this.$ekmtcCommon.isEmpty(this.msgData.blPcupDt) ? this.msgData.blPcupDt : '',
        blErrCd: this.msgData.blErrCd ? this.msgData.blErrCd : '',
        oblContent: this.msgData.oblContent ? this.msgData.oblContent : ''
      }
      this.msgVo = vo

      this.reqErrMsg = []

      if (this.$_.trim(vo.blStCd) !== '') {
        // BL 메시지 팝업 제목 세팅
        let pageTitle = this.$t('msg.CSBL220_STS.0' + this.$_.trim(vo.blStCd))

        if (vo.blStCd === '89') { // PENDING
          pageTitle = 'PENDING'
        }

        this.pageTitle = pageTitle

        // 팝업 내용 세팅
        if (this.msgVo.blStCd === '11' || this.msgVo.blStCd === '01' || this.msgVo.blStCd === '02' || this.msgVo.blStCd === '03') {
          this.msgVo.obrdDt = moment(this.msgVo.obrdDt).format('YYYY.MM.DD')

          if (!this.$ekmtcCommon.isEmpty(this.msgVo.blPcupDt)) {
            this.msgVo.blPcupDt = moment(this.msgVo.blPcupDt, 'YYYYMMDDhhmm').format('YYYY.MM.DD hh:mm')
          }
        }

        if (vo.blStCd === '99') {
          if (this.memberDetail.userCtrCd !== 'JP' && vo.cntrStsCd === 'N' && vo.elYn === 'N') {
            this.reqErrMsg.push(`${this.$t('art.CSBL220.003_1')}`) // 사유 : 컨테이너 미반입
            this.reqErrMsg.push(`${this.$t('art.CSBL220.003_2')}`) // 사유 : 면장 미입력
            this.reqErrMsg.push(`${this.$t('art.CSBL220.003_3')}`) // 컨테이너가 지정된 CY로 반입되지 않았습니다.
            this.reqErrMsg.push(`${this.$t('art.CSBL220.003_4')}`) // 수출면장이 입력되지 않았습니다.
            this.reqErrMsg.push(`${this.$t('art.CSBL220.003_5')}`) // 확인 후 다시 요청해 주시기 바랍니다.

            this.pageTitle = pageTitle
          } else if (this.memberDetail.userCtrCd !== 'JP' && vo.elYn === 'N') {
            this.reqErrMsg.push(`${this.$t('art.CSBL220.001_1')}`) // 사유 : 면장 미입력
            this.reqErrMsg.push(`${this.$t('art.CSBL220.001_2')}`) // 수출면장이 입력되지 않았습니다.
            this.reqErrMsg.push(`${this.$t('art.CSBL220.001_3')}`) // 확인 후 다시 요청해 주시기 바랍니다.

            this.pageTitle = pageTitle
          } else if (this.memberDetail.userCtrCd !== 'JP' && vo.cntrStsCd === 'N' && vo.cntrCfsYn === 'N') {
            this.reqErrMsg.push(`${this.$t('art.CSBL220.002_1')}`) // 사유 : 컨테이너 미반입
            this.reqErrMsg.push(`${this.$t('art.CSBL220.002_2')}`) // 컨테이너가 지정된 CY로 반입되지 않았습니다.
            this.reqErrMsg.push(`${this.$t('art.CSBL220.002_3')}`) // 확인 후 다시 요청해 주시기 바랍니다.

            this.pageTitle = pageTitle
          } else {
            // 팝업 타이틀
            this.pageTitle = this.$t('msg.SETT010T010.136') // '신청가능'
          }
        }
      }

      let loiReqErrMsg = []
      const loiCodeList = this.$_.split(vo.loiReqCatCd, ',')

      loiCodeList.forEach(el => {
        let msg = ''
        if (el === '01') {
          msg = this.$t('art.ADD20220221.000') //'제 3국 SHPR LOI가 처리되지 않았습니다.'
        } else if (el === '02') {
          msg = this.$t('art.ADD20220221.001') //'중량초과각서가 처리되지 않았습니다.'
        } else if (el === '03') {
          msg = this.$t('art.ADD20220221.002') // 'DOP 선적 LOI가 처리되지 않았습니다.'
        } else if (el === '04') {
          msg = this.$t('tem.CMAT037') //서류 담당자와 확인 부탁드립니다. `ETC '${vo.loiEtcRmk}'` + this.$t('art.ADD20220221.003')
        }
        if (msg !== '') {
          loiReqErrMsg.push(msg)
        }
      })
      this.loiReqErrMsg = loiReqErrMsg

      this.blErrorMsg = []
      const blErrCodes = this.$_.split(vo.blErrCd, ';')
      blErrCodes.forEach(cd => {
        let msg = ''
        let rank = -1

        if (this.memberDetail.userCtrCd === 'KR') {
          cd = '2' + this.$_.trim(cd)
        } else {
          cd = '1' + this.$_.trim(cd)
        }

        if (cd === '232' || cd === '132') rank = 1
        if (cd === '230' || cd === '130') rank = 2
        if (cd === '221' || cd === '121') rank = 3
        if (cd === '220' || cd === '120') rank = 4
        if (cd === '290' || cd === '190') rank = 5
        if (cd === '213' || cd === '113') rank = 6
        if (cd === '205' || cd === '105') rank = 7
        if (cd === '212' || cd === '112') rank = 8
        if (cd === '235' || cd === '135') rank = 9
        if (this.msgVo.cntrStsCd === 'N') { // 컨테이너 미반입인데, icc B/L portal 에서 CFM 체크 했을때, 'Y' 이면 에러표시안함.
          if (cd === '216' || cd === '116') rank = 10
        }
        if (cd === '211' || cd === '111') rank = 11
        if (cd === '224' || cd === '124') rank = 12
        if (cd === '227' || cd === '127') rank = 13
        if (cd === '204' || cd === '104') rank = 14
        if (cd === '229' || cd === '129') rank = 15
        if (cd === '244' || cd === '144') rank = 16
        if (cd === '217' || cd === '117') rank = 17

        const errs = this.errorCodes.filter(vo => vo.blErrCd === cd)
        if (errs.length > 0) {
          msg = errs[0].blErrMsg ? errs[0].blErrMsg : ''
          if (cd === '212' || cd === '235') {
            // 온보드 날자표기 대상 분리
          }
          if (rank !== -1) {
            this.blErrorMsg.push(
              {
                code: cd,
                message: msg,
                rank: rank
              }
            )
          }
        }
      })
      this.blErrorMsg = this.$_.sortBy(this.blErrorMsg, ['rank'])

      let plcCd = ''
      if (this.msgVo.blStCd === '01' || this.msgVo.blStCd === '11' || this.msgVo.blStCd === '12' || this.msgVo.blStCd === '13' || this.msgVo.blStCd === '14') {
        this.blApvUser.blApvNm = this.msgVo.oblNm ? this.msgVo.oblNm : ''
        this.blApvUser.blApvTel = this.msgVo.oblTel ? this.msgVo.oblTel : ''
        this.blApvUser.issuedBy = this.msgVo.oblAreaNm ? this.msgVo.oblAreaNm : ''
        plcCd = this.msgVo.oblArea
      } else if (this.msgVo.blStCd === '02') {
        this.blApvUser.blApvNm = this.msgVo.surNm ? this.msgVo.surNm : ''
        this.blApvUser.blApvTel = this.msgVo.surTel ? this.msgVo.surTel : ''
        this.blApvUser.issuedBy = this.msgVo.surAreaNm ? this.msgVo.surAreaNm : ''
        plcCd = this.msgVo.surArea
      } else if (this.msgVo.blStCd === '03') {
        this.blApvUser.blApvNm = this.msgVo.wayNm ? this.msgVo.wayNm : ''
        this.blApvUser.blApvTel = this.msgVo.wayTel ? this.msgVo.wayTel : ''
        this.blApvUser.issuedBy = this.msgVo.wayAreaNm ? this.msgVo.wayAreaNm : ''
        plcCd = this.msgVo.wayArea
      } else if (this.msgVo.blStCd === '88' || this.msgVo.blStCd === '89') {
        this.blApvUser.blApvNm = this.msgVo.rejectNm ? this.msgVo.rejectNm : ''
        this.blApvUser.blApvTel = this.msgVo.rejectTel ? this.msgVo.rejectTel : ''
        this.blApvUser.issuedBy = this.msgVo.rejectAreaNm ? this.msgVo.rejectAreaNm : ''
        plcCd = this.msgVo.rejectArea
      }
      if (plcCd === 'SEL') {
        this.blApvUser.issuedBy = this.$t('msg.KMTC118.006') // '서울특별시 중구 남대문로2가 118 한진빌딩 15층(신관) 고려해운 DESK'
      } else if (plcCd === 'PUS') {
        this.blApvUser.issuedBy = this.$t('msg.KMTC118.007') // '부산광역시 중구 중앙동4가 88-7 교보생명빌딩 10층'
      } else if (plcCd === 'USN') {
        this.blApvUser.issuedBy = this.$t('msg.KMTC118.008') //'울산광역시 남구 용연로 370 (황성동) 울산동방아이포트 4층'
      } else if (plcCd === 'INC') {
        this.blApvUser.issuedBy = this.$t('msg.KMTC118.010') //'인천 광역시 중구 신흥동 3가 7-241 정석빌딩 신관 8층'
      }
    },

    async getBlErrorCodes () {
      await calcIssueApi.getBlErrorCodes()
        .then(async response => {
          this.errorCodes = response.data
        })
    }
  }
}
</script>
